<template>
	<div id="body">
		<div id="Sinheader">
			<el-page-header @back="goBack" content="核对订单">
			</el-page-header>
		</div>
		<div id="inmain">
			<p>收货人信息</p>
			<div id="text">
				<span>账户名称</span>
				<el-input placeholder="例如:JXCDemo" v-model="USername" clearable @change='Sellogfun'>
				</el-input>
			</div>
			<div id="text">
				<span>邀请码</span>
				<el-input placeholder="选填" v-model="Invitationcode" clearable>
				</el-input>
			</div>
			<div style="width: 87%; margin-left: 7%;">
				<span>地址</span>
				<el-input placeholder="必填" v-model="location" clearable>
				</el-input>
			</div>
		</div>
		<div id="inmain">
			<p>版本信息
				<el-tooltip class="item" effect="dark" content="输入账户名称自动选择,如需升级账户人数上限请拨打:4006182019" placement="right">
					<i class="el-icon-question" style="cursor: pointer;"></i>
				</el-tooltip>
			</p>
			<div class="verbox">
				<button v-for="(item,i) in shopobj.Slave" :key="i"
					:class="datobj.AccountScale==null&&(datobj.Slave?(datobj.Slave[i].ID==item.ID?datobj.Slave[i].IsDiscount:false):false)&&UserKey!=''?'Bclaname':datobj.AccountScaleID==item.ID?'Bclaname':''"
					type="button">{{item.UserSNum}}-{{item.UserFNum}}人版
					<span style="display: block;text-align: center;font-size: 12px;">{{item.Price}}元/年</span>
				</button>

			</div>
		</div>
		<!-- <div id="inmain" v-if="!payflage">
			<p>版本信息
				<el-tooltip class="item" effect="dark" content="输入账户名称自动选择,如需升级账户人数上限请拨打:4006182019" placement="right">
					<i class="el-icon-question" style="cursor: pointer;"></i>
				</el-tooltip>
			</p>
			<div class="verbox">
				<button v-for="(item,i) in shopobj.Slave" :key="i"
					:class="datobj.AccountScale==null&&i==0&&UserKey!=''?'Bclaname':datobj.AccountScaleID==item.ID?'Bclaname':''"
					type="button">{{item.UserSNum}}-{{item.UserFNum}}人版
					<span style="display: block;text-align: center;font-size: 12px;">{{item.Price}}元/年</span>
				</button>

			</div>
		</div> -->
		<div id="inmain" v-if="payflage">
			<p>付款方式</p>
			<div id="rio">
				<!-- <el-radio style="padding-top:2px;" v-model="radio" label="2" border>
					<img style="width: 95px; height: 35px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wpay.png">
				</el-radio> -->
				<el-radio style="padding-top: 5px;" v-model="radio" label="1" border>
					<img style="width: 90px; height: 30px;"
						src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/zfb.png">
				</el-radio>
			</div>
		</div>
		<div id="box" style="margin-top: 20px;">
			<div id="tit">
				<p>商品</p>
				<p>{{shopobj.OriginalPrice?'折后价(元)':'单价(元)'}}</p>
				<p v-if="shopobj.OriginalPrice">原价(元)</p>
				<p>数量</p>
				<p>小计(元)</p>
				<p>备注</p>
			</div>
			<div id="shop">
				<div id="">
					<img v-if="shopobj.Url" :src="shopobj.Url">
					<span>{{shopobj.Name}}</span>
				</div>
				<p :class="shopobj.OriginalPrice?'Opclas':''">
					￥{{shopobj.Prices}}
				</p>
				<p v-if="shopobj.OriginalPrice" style="color:#909399;text-decoration:line-through;">
					￥{{shopobj.OriginalPrice}}</p>
				<p>1</p>
				<p>{{shopobj.Prices}}</p>
				<el-input placeholder="选填" v-model="remark" clearable></el-input>
			</div>
		</div>
		<div id="FP">
			<p style="display: flex;justify-content: space-between; align-items: center;">电子发票
				<template>
					<el-radio v-model="radios" label="1" border style="background-color: #FFFFFF;color: #000000;">需要
					</el-radio>
					<el-radio v-model="radios" label="2" border
						style="margin-right: 5%;background-color: #FFFFFF;color: #000000;">不需要</el-radio>
				</template>
			</p>
			<div id="rio" v-if="radios=='1'">
				<el-radio v-model="unit" label="1">个人</el-radio>
				<el-radio v-model="unit" label="2">企业</el-radio>
				<div id="Fpbox" v-if="unit=='1'">
					<el-input placeholder="请输入内容" v-model="rise">
						<template slot="prepend">抬头：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="dutyparagraph" style="margin-top: 30px;">
						<template slot="prepend">税号：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="Email" style="margin-top: 30px;margin-bottom: 30px;">
						<template slot="prepend">邮箱：</template>
					</el-input>
				</div>
				<div id="Fpbox" v-if="unit=='2'">
					<el-input placeholder="请输入内容" v-model="rise">
						<template slot="prepend">抬头：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="dutyparagraph" style="margin-top: 30px;">
						<template slot="prepend">税号：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="Businessaddress" style="margin-top: 30px;">
						<template slot="prepend">单位地址：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="enterprisephone" style="margin-top: 30px;">
						<template slot="prepend">电话号码：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="bankaddress" style="margin-top: 30px;">
						<template slot="prepend">开户银行：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="Accountnumberofopeningbank" style="margin-top: 30px;">
						<template slot="prepend">银行账户：</template>
					</el-input>
					<el-input placeholder="请输入内容" v-model="Email" style="margin-top: 30px;margin-bottom: 30px;">
						<template slot="prepend">邮箱：</template>
					</el-input>
				</div>
			</div>
		</div>
		<div id="btn">
			<button type="button" @click="Payfun">{{!shopobj.IsDiscount?'确认续费':'特惠续费'}}</button>
		</div>
		<el-dialog title="微信扫码支付" :visible.sync="centerDialogVisible" width="30%" center>
			<div id="codebox" :key="CodeboxKey">
				<icon-svg class='iconclas' iconClass='weixinzhifu2'></icon-svg>
				<span>微信支付</span>
				<div class="qrcode" ref="qrCodeUrl"></div>
				<div id="txtbox">
					<p>订单编号:<span>{{DDCode}}</span></p>
					<p>商品名称:<span>{{Name}}</span></p>
					<p>订单金额:<span class="pic">￥{{Pirce}}</span></p>
					<p>下单时间:<span>{{Time}}</span></p>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<div id="succ">

		</div>
		<el-dialog title="账户信息" :visible.sync="DPflage" width="30%" center>
			<div id="DPbox">
				<p>店铺名称:{{datobj.UserName}}</p>
				<p>所属行业:{{datobj.DataTradName}}</p>
				<p>到期时间:{{datobj.Deadline}}</p>
				<p>联系电话:{{datobj.Phone}}</p>
				<p>电子邮箱:{{datobj.Emiall}}</p>
				<p>联系地址:{{datobj.Address}}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DPflage = false">取 消</el-button>
				<el-button type="primary" @click="DPflage = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="账户信息" :visible.sync="PhDPflage" width="90%" center>
			<div id="DPbox">
				<p>店铺名称:{{datobj.UserName}}</p>
				<p>所属行业:{{datobj.DataTradName}}</p>
				<p>到期时间:{{datobj.Deadline}}</p>
				<p>联系电话:{{datobj.Phone}}</p>
				<p>电子邮箱:{{datobj.Emiall}}</p>
				<p>联系地址:{{datobj.Address}}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="PhDPflage = false">取 消</el-button>
				<el-button type="primary" @click="PhDPflage = false">确 定</el-button>
			</span>
		</el-dialog>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	import QRCode from 'qrcodejs2'
	const axi = require('../../assets/axi.js')
	export default {
		components: {
			footers
		},
		data() {
			return {
				wxflage: false,
				payflage: false,
				CodeboxKey: 1, //二维码key
				rise: '', //发票抬头
				dutyparagraph: "", //税号
				Email: "", //邮箱
				USername: '', //账户名称
				Invitationcode: "",
				location: "",
				radio: '1',
				remark: "",
				radios: "2",
				unit: "1", //个人/企业
				shopobj: {},
				datobj: {},
				centerDialogVisible: false,
				DPflage: false, //店铺信息
				PhDPflage: false, //手机店铺信息
				DDCode: "", //订单编号
				Name: "", //商品名称
				Pirce: "", //价格
				Time: "", //下单时间
				UserKey: "", //输入账户的key
				times: '', //支付计时器
				prepay_id: "",
				InvitationCodes: "",
				Businessaddress: "", //企业地址
				enterprisephone: "", //企业电话
				bankaddress: "", //开户行地址
				Accountnumberofopeningbank: "", //开户行账号
			}
		},
		mounted() {
			this.shopobj = this.$store.state.shopid
			console.log(this.shopobj)
			this.shopobj.Prices=this.shopobj.Price
			console.log(this.$store.state.Invitationcode)
			if (window.innerWidth > 720) {
				this.payflage = true
			} else {
				this.payflage = false
			}
			if (this.$store.state.Invitationcode) {
				this.Invitationcode = this.$store.state.Invitationcode
			}

			var browser = {
				versions: function() {
					var u = navigator.userAgent,
						app = navigator.appVersion;
					return { //移动终端浏览器版本信息
						trident: u.indexOf('Trident') > -1, //IE内核
						presto: u.indexOf('Presto') > -1, //opera内核
						webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
						gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
						mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
						android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
						iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
						iPad: u.indexOf('iPad') > -1, //是否iPad
						webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
					};
				}(),
				language: (navigator.browserLanguage || navigator.language).toLowerCase()
			}
			if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
				var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					this.wxflage = true
					let code = ''
					var daurl = window.location.href
					let dats = ''
					let strToObj = {}
					if (daurl.split('?')[1]) {
						dats = decodeURIComponent(daurl.split('?')[1].split('#')[0])
						code = dats.split('&')[0].split('=')[1]
						strToObj = JSON.parse(dats.split('&')[1].split('=')[1])
						console.log(code)
						console.log(strToObj)
						this.USername = strToObj.UserName
						this.location = strToObj.Address
						this.radio = strToObj.radio
						this.radios = strToObj.radios
						this.rise = strToObj.rise, // 抬头
							this.dutyparagraph = strToObj.dutyparagraph, //税号
							this.Email = strToObj.Email //邮箱
						this.Invitationcode = strToObj.Invitationcode
						this.InvitationCodes = strToObj.InvitationCodes
						this.unit = strToObj.Unit
						this.Businessaddress = strToObj.Businessaddress
						this.enterprisephone = strToObj.enterprisephone
						this.bankaddress = strToObj.bankaddress
						this.Accountnumberofopeningbank = strToObj.Accountnumberofopeningbank
						let Is_Invoice = false
						let Header = ''
						let TaxNumber = ''
						let Email = ''
						let InvoiceType = ''
						let UnitAddress = ''
						let InvoicePhone = ''
						let InvoiceBank = ''
						let BankAccount = ''
						if (this.radios == '1') {
							Is_Invoice = true
							Header = this.rise
							TaxNumber = this.dutyparagraph
							Email = this.Email
							InvoiceType = this.unit
							if (this.unit == '2') {
								UnitAddress = this.Businessaddress
								InvoicePhone = this.enterprisephone
								InvoiceBank = this.bankaddress
								BankAccount = this.Accountnumberofopeningbank
							}
						}
						let Odatas = {
							Key: strToObj.Key,
							UserName: strToObj.UserName,
							UserKey: strToObj.UserKey,
							Address: strToObj.Address,
							Remarks: strToObj.Remarks,
							Code: code,
							InvitationCode: strToObj.Invitationcode,
							Is_Invoice: Is_Invoice,
							Header: Header,
							TaxNumber: TaxNumber,
							Email: Email,
							UserInvitationCode: this.InvitationCodes,
							InvoiceType: InvoiceType,
							UnitAddress: UnitAddress,
							InvoicePhone: InvoicePhone,
							InvoiceBank: InvoiceBank,
							BankAccount: BankAccount
						}
						console.log(Odatas)
						axi.post('Pay/WeChatPayJSAPI', Odatas).then((res) => {
							console.log(res)
							if (res.data.Code == 1000) {
								console.log(res)
								let obj = decodeURIComponent(res.data.Dto)
								let objs = JSON.parse(obj)
								console.log(objs)
								this.prepay_id = objs.prepay_id
								// alert(objs.prepay_id)
								this.$store.commit('prepayidfun', objs.prepay_id)

								function onBridgeReady() {
									WeixinJSBridge.invoke('getBrandWCPayRequest', {
											appId: objs.appId, //公众号ID，由商户传入    
											timeStamp: objs.timeStamp, //时间戳，自1970年以来的秒数    
											nonceStr: objs.nonceStr, //随机串    
											package: objs.package,
											signType: objs.signType, //微信签名方式：    
											paySign: objs.paySign //微信签名
										},
										function(res) {
											// alert(res.err_msg)
											if (res.err_msg == "get_brand_wcpay_request:ok") {
												window.location.href = 'http://www.rhdgj.com/#/Success'
												this.rise = '' //发票抬头
												this.dutyparagraph = "" //税号
												this.Email = "" //邮箱
												this.USername = '' //账户名称
												this.Invitationcode = "" //邀请码
												this.location = "" //地址
												this.radio = '1' //支付方式
												this.remark = "" //备注
												this.radios = "2" //是否需要发票
												this.prepay_id = "" //支付单据判断
												// 使用以上方式判断前端返回,微信团队郑重提示：
												//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
											} else {
												// this.$message.info('支付失败')
												alert('支付失败!')
											}
										});
								}
								if (typeof WeixinJSBridge == "undefined") {
									if (document.addEventListener) {
										document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
									} else if (document.attachEvent) {
										document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
										document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
									}
								} else {
									onBridgeReady();
								}
							} else {
								this.$message.error(res.data.Msg)
							}
						})
					}
				}
			}

		},
		methods: {
			Sellogfun() { //查询账户信息
				let data = {
					UserName: this.USername
				}
				console.log(123)
				axi.post('Pay/AccountMessage', data).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						if (res.data.Dto) {
							if (res.data.Dto.DataTradId != this.shopobj.Type) {
								this.$alert('当前账户不可续费其他行业账户,请重新选择!', '提示', {
									confirmButtonText: '确定',
									callback: action => {
										this.USername = ''
										this.UserKey = ''
										if (this.datobj.AccountScaleID) {
											this.datobj.AccountScaleID = ''
										}
										this.shopobj.OriginalPrice = ''
									}
								});
								return
							}
							this.InvitationCodes = res.data.Dto.InvitationCode
							this.UserKey = res.data.Dto.UserKey
							// res.data.Dto.Deadline=res.data.Dto.Deadline.split('.')[0].repeat('T',' ')
							if (res.data.Dto.AccountScale != null) {
								res.data.Dto.AccountScaleID = JSON.parse(res.data.Dto.AccountScale).ID
								res.data.Dto.AccountScaleUserFNum = JSON.parse(res.data.Dto.AccountScale).UserFNum
								console.log(this.shopobj)
								res.data.Dto.Slave.map(item => {
									if (res.data.Dto.AccountScaleID == item.ID) {
										this.shopobj.Prices = item.Price
									}
								})
							} else {
								console.log(res.data.Dto.AccountScale)
								console.log(res.data.Dto.Slave)
								res.data.Dto.Slave.map(item => {
									if (item.IsDiscount) {
										this.shopobj.OriginalPrice = item.Price
										this.shopobj.IsDiscount  = item.IsDiscount 
									}
								})
							}
							this.datobj = res.data.Dto
							this.shopobj = this.$store.state.shopid
							this.datobj.Deadline = this.datobj.Deadline.split('.')[0].replace('T', ' ')
							if (window.innerWidth > 720) {
								this.DPflage = true
							} else {
								this.PhDPflage = true
							}
						} else {
							this.$message.error("此账户不存在请重新输入!")
							this.USername = ''
							this.UserKey = ''
							if (this.datobj.AccountScaleID) {
								this.datobj.AccountScaleID = ''
							}
							this.shopobj.Prices = this.shopobj.Slave[0].Price
						}
						this.$forceUpdate()
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			goBack() {
				this.$router.back()
			},
			Payfun() {
				if (this.USername == '') {
					this.$message.info('请输入账户名称！')
					return
				}
				if (this.location == '') {
					this.$message.info('请输入地址！')
					return
				}
				if (this.radios == '1') {
					if (this.unit == '1') {
						if (this.rise == '') {
							this.$message.info('请输入发票抬头！')
							return
						}
						if (this.dutyparagraph == '') {
							this.$message.info('请输入发票税号！')
							return
						}
						if (this.Email == '') {
							this.$message.info('请输入邮箱！')
							return
						} else {
							var reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
							if (!reg.test(this.Email)) {
								this.$message.info('请输入正确邮箱！')
								return
							}

						}
					} else {
						if (this.rise == '') {
							this.$message.info('请输入发票抬头！')
							return
						}
						if (this.dutyparagraph == '') {
							this.$message.info('请输入发票税号！')
							return
						}
						if (this.Businessaddress == '') {
							this.$message.info('请输入单位地址！')
							return
						}
						if (this.enterprisephone == '') {
							this.$message.info('请输入电话号码！')
							return
						}
						if (this.bankaddress == '') {
							this.$message.info('请输入开户银行！')
							return
						}
						if (this.Accountnumberofopeningbank == '') {
							this.$message.info('请输入银行账户！')
							return
						}
						if (this.Email == '') {
							this.$message.info('请输入邮箱！')
							return
						} else {
							var reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
							if (!reg.test(this.Email)) {
								this.$message.info('请输入正确邮箱！')
								return
							}

						}
					}
				}
				// 电脑支付
				if (window.innerWidth > 720) {
					// 微信支付
					if (this.radio == '2') {
						let Is_Invoice = false
						let Header = ''
						let TaxNumber = ''
						let Email = ''
						let InvoiceType = ''
						let UnitAddress = ''
						let InvoicePhone = ''
						let InvoiceBank = ''
						let BankAccount = ''
						if (this.radios == '1') {
							Is_Invoice = true
							Header = this.rise
							TaxNumber = this.dutyparagraph
							Email = this.Email
							InvoiceType = this.unit
							if (this.unit == '2') {
								UnitAddress = this.Businessaddress
								InvoicePhone = this.enterprisephone
								InvoiceBank = this.bankaddress
								BankAccount = this.Accountnumberofopeningbank
							}
						}
						let data = {
							Key: this.shopobj.Key,
							UserName: this.USername, //账号名称
							UserKey: this.UserKey, //用户Key
							Address: this.location, //地址
							Remarks: this.remark, //备注
							InvitationCode: this.Invitationcode,
							Is_Invoice: Is_Invoice,
							Header: Header,
							TaxNumber: TaxNumber,
							Email: Email,
							UserInvitationCode: this.InvitationCodes,
							InvoiceType: InvoiceType,
							UnitAddress: UnitAddress,
							InvoicePhone: InvoicePhone,
							InvoiceBank: InvoiceBank,
							BankAccount: BankAccount
						}
						console.log(data)
						axi.post('Pay/GetPayUrl', data).then((res) => {
							console.log(res)
							if (res.data.Code == 1000) {
								if (res.data.Dto.Is_Complete) {
									this.$alert('已有订单暂未支付,点击确定继续支付', '继续支付', {
										confirmButtonText: '确定',
										callback: action => {
											this.centerDialogVisible = true
											this.$nextTick(() => {
												let qrcode = new QRCode(this.$refs.qrCodeUrl, {
													text: res.data.Dto
														.Url, // 需要转换为二维码的内容
													width: 150,
													height: 150,
													colorDark: '#000000',
													colorLight: '#ffffff',
													correctLevel: QRCode.CorrectLevel.H
												})
											})
											// this.$message({
											// 	type: 'info',
											// 	message: `action: ${ action }`
											// });
										}
									});
								} else {
									this.centerDialogVisible = true
									this.$nextTick(() => {
										let qrcode = new QRCode(this.$refs.qrCodeUrl, {
											text: res.data.Dto.Url, // 需要转换为二维码的内容
											width: 150,
											height: 150,
											colorDark: '#000000',
											colorLight: '#ffffff',
											correctLevel: QRCode.CorrectLevel.H
										})
									})
								}
								this.DDCode = res.data.Dto.Out_trade_no
								this.Name = res.data.Dto.GoodName
								this.Pirce = res.data.Dto.Pirce
								this.Time = res.data.Dto.Time.split('.')[0].replace('T', ' ')
								this.CodeboxKey = this.CodeboxKey + 1
								this.paytime()
							} else {
								this.$message.error(res.data.Msg)
							}
						})
					} else { //支付宝支付
						// this.$message.info('暂未开放')
						let Is_Invoice = false
						let Header = ''
						let TaxNumber = ''
						let Email = ''
						let InvoiceType = ''
						let UnitAddress = ''
						let InvoicePhone = ''
						let InvoiceBank = ''
						let BankAccount = ''
						if (this.radios == '1') {
							Is_Invoice = true
							Header = this.rise
							TaxNumber = this.dutyparagraph
							Email = this.Email
							InvoiceType = this.unit
							if (this.unit == '2') {
								UnitAddress = this.Businessaddress
								InvoicePhone = this.enterprisephone
								InvoiceBank = this.bankaddress
								BankAccount = this.Accountnumberofopeningbank
							}
						}
						console.log(this.datobj)
						let data = {
							Key: this.shopobj.Key,
							DataTradId: this.datobj.DataTradId,
							UserName: this.USername,
							UserKey: this.UserKey,
							Address: this.location,
							Remarks: this.remark,
							InvitationCode: this.Invitationcode,
							UserInvitationCode: this.InvitationCodes,
							Is_Invoice: Is_Invoice,
							Header: Header,
							TaxNumber: TaxNumber,
							Email: Email,
							InvoiceType: InvoiceType,
							UnitAddress: UnitAddress,
							InvoicePhone: InvoicePhone,
							InvoiceBank: InvoiceBank,
							BankAccount: BankAccount
						}
						console.log(data)
						axi.post('Pay/AlipayPC', data).then((res) => {
							console.log(res)
							if (res.data.Code == 1000) {
								let from = res.data.Dto.split('<script>')[0]
								console.log(from)
								const div = document.createElement('div');
								div.innerHTML = from;
								document.body.appendChild(div);
								document.forms[0].submit();
								document.body.removeChild(div)
							} else {
								this.$message.error(res.data.Msg)
							}
						})

						// document.forms['alipaysubmit'].submit();
						// document.body.removeChild(div)
					}
				} else {
					// 微信支付
					console.log(123)
					let data = {
						Key: this.shopobj.Key,
						UserName: this.USername, //账号名称
						UserKey: this.UserKey, //用户Key
						Address: this.location, //地址
						Remarks: this.remark, //备注
						radio: this.radio, //支付方式
						radios: this.radios, //发票选择
						rise: this.rise, // 抬头
						dutyparagraph: this.dutyparagraph, //税号
						Email: this.Email, //邮箱
						Invitationcode: this.Invitationcode,
						InvitationCodes: this.InvitationCodes,
						Pic: this.shopobj.Prices,
						Unit: this.unit,
						Businessaddress: this.Businessaddress,
						enterprisephone: this.enterprisephone,
						bankaddress: this.bankaddress,
						DataTradId: this.datobj.DataTradId,
						Accountnumberofopeningbank: this.Accountnumberofopeningbank
					}
					console.log(data)
					this.$store.commit('Phonepaydatafun', data)
					this.$router.push('/phonePay')
					// if (this.radio == '2') {
					// 	var browser = {
					// 		versions: function() {
					// 			var u = navigator.userAgent,
					// 				app = navigator.appVersion;
					// 			return { //移动终端浏览器版本信息
					// 				trident: u.indexOf('Trident') > -1, //IE内核
					// 				presto: u.indexOf('Presto') > -1, //opera内核
					// 				webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
					// 				gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
					// 				mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
					// 				ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
					// 				android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -
					// 				1, //android终端或uc浏览器
					// 				iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
					// 				iPad: u.indexOf('iPad') > -1, //是否iPad
					// 				webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
					// 			};
					// 		}(),
					// 		language: (navigator.browserLanguage || navigator.language).toLowerCase()
					// 	}
					// 	if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
					// 		var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
					// 		if (ua.match(/MicroMessenger/i) == "micromessenger") {
					// 			// alert('wx')
					// 			let data = {
					// 				Key: this.shopobj.Key,
					// 				UserName: this.USername, //账号名称
					// 				UserKey: this.UserKey, //用户Key
					// 				Address: this.location, //地址
					// 				Remarks: this.remark, //备注
					// 				radio: this.radio, //支付方式
					// 				radios: this.radios, //发票选择
					// 				rise: this.rise, // 抬头
					// 				dutyparagraph: this.dutyparagraph, //税号
					// 				Email: this.Email, //邮箱
					// 				Invitationcode: this.Invitationcode,
					// 				InvitationCodes: this.InvitationCodes
					// 			}
					// 			var newString = JSON.stringify(data)
					// 			window.location.href =
					// 				'https://open.weixin.qq.com/connect/oauth2/authorize?Appid=wxc5a84247c3c9948f&redirect_uri=http%3A%2F%2Fwww.rhdgj.com%2F%23%2Finformation&response_type=code&scope=snsapi_base&state=' +
					// 				newString + '#wechat_redirect'

					// 		}
					// 		if (browser.versions.ios || browser.versions.android) {
					// 			//是否在IOS浏览器打开
					// 			// alert('ios')
					// 			this.webpay()
					// 			// let ip = returnCitySN['cip']
					// 			// window.location.href =
					// 			// 	"weixin://wap/pay?appid%3Dwx2421b1c4370ec43b%26noncestr%3D3e84679af4efab5f32ee9ea01b2ec290%26package%3DWAP%26prepayid%3Dwx20160504154919fdacd7bc0d0127918780%26timestamp%3D1462348159%26sign%3DC40DC4BB970049D6830BA567189B463B"
					// 		}
					// 	} else {
					// 		this.webpay()
					// 	}
					// } else {
					// 	// this.$message.info('暂未开放')
					// 	let Is_Invoice = false
					// 	let Header = ''
					// 	let TaxNumber = ''
					// 	let Email = ''
					// 	if (this.radios == '1') {
					// 		Is_Invoice = true
					// 		Header = this.rise
					// 		TaxNumber = this.dutyparagraph
					// 		Email = this.Email
					// 	}
					// 	let data = {
					// 		Key: this.shopobj.Key,
					// 		UserName: this.USername,
					// 		UserKey: this.UserKey,
					// 		Address: this.location,
					// 		Remarks: this.remark,
					// 		InvitationCode: this.Invitationcode,
					// 		UserInvitationCode: this.InvitationCodes,
					// 		Is_Invoice: Is_Invoice,
					// 		Header: Header,
					// 		TaxNumber: TaxNumber,
					// 		Email: Email
					// 	}
					// 	console.log(data)
					// 	axi.post('Pay/AlipayPhone',data).then((res)=>{
					// 		console.log(res)
					// 		console.log('手机')
					// 		if(res.data.Code==1000){
					// 			let from=res.data.Dto.split('<script>')[0]
					// 			console.log(from)
					// 			const div = document.createElement('div');
					// 			div.innerHTML = from;
					// 			document.body.appendChild(div);
					// 			document.forms[0].submit();
					// 			document.body.removeChild(div)
					// 		}else{
					// 			this.$message.error(res.data.Msg)
					// 		}
					// 	})
					// }
				}
			},
			// H5支付
			// webpay() {
			// 	let ip = returnCitySN['cip']
			// 	console.log(ip)
			// 	let Is_Invoice = false
			// 	let Header = ''
			// 	let TaxNumber = ''
			// 	let Email = ''
			// 	if (this.radios == '1') {
			// 		Is_Invoice = true
			// 		Header = this.rise
			// 		TaxNumber = this.dutyparagraph
			// 		Email = this.Email
			// 	}
			// 	let data = {
			// 		Key: this.shopobj.Key,
			// 		UserName: this.USername,
			// 		UserKey: this.UserKey,
			// 		Address: this.location,
			// 		Remarks: this.remark,
			// 		Spbill_create_ip: ip,
			// 		Is_Invoice: Is_Invoice,
			// 		Header: Header,
			// 		TaxNumber: TaxNumber,
			// 		Email: Email,
			// 		InvitationCode: this.Invitationcode,
			// 		UserInvitationCode: this.InvitationCodes
			// 	}
			// 	console.log(data)
			// 	axi.post('Pay/WeChatPayMWEBAPI', data).then((res) => {
			// 		console.log(res)
			// 		if (res.data.Code == 1000) {
			// 			this.$store.commit('prepayidfun', res.data.Dto.Prepay_id)
			// 			// let Url=res.data.Dto.Url.split('https://')[1]
			// 			// console.log(Url)
			// 			res.data.Dto.Url = res.data.Dto.Url +
			// 				'&redirect_url=http%3A%2F%2Fwww.rhdgj.com%2F%23%2FSuccess'
			// 			console.log(res.data.Dto.Url)
			// 			window.location.href = res.data.Dto.Url
			// 		} else {
			// 			this.$message.error(res.data.Msg)
			// 		}
			// 	})
			// },
			paytime() {
				let data = {
					Out_trade_no: this.DDCode,
					UserName: this.USername,
				}
				this.times = setInterval(() => {
					axi.post('Pay/Is_WeChatPay', data).then((res) => {
						console.log(res)
						if (res.data.Dto == 'SUCCESS') {
							this.centerDialogVisible = false
							this.$notify({
								title: '支付成功',
								message: '您的系统已续费成功，请登录后台查看！',
								type: 'success'
							});
							setTimeout(() => {
								this.$router.push('/productdetails')
							}, 1000)
							clearInterval(this.times)
						}
					})
				}, 1000)
			}
		},
		watch: {
			centerDialogVisible: function(val, oldVal) {
				console.log(val)
				if (!val) {
					clearInterval(this.times)
				}
			},
		},
		beforeDestroy() {
			clearInterval(this.times)
		}
	}
</script>

<style scoped="scoped" lang="less">
	// 电脑
	@media screen and (min-width:720px) {
		#Sinheader {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			box-shadow: 1px 1px 10px #dfd9d9;
			border-radius: 0px 0px 10px 10px;
			padding-left: 2%;
		}

		.Opclas {
			color: red;
		}

		.verbox {
			height: 84%;
			display: flex;
			align-content: center;
			align-items: center;
			flex-wrap: wrap;

			button {
				width: 20%;
				height: 40px;

				color: #C0C4CC;
				border: 1px solid #C0C4CC;
				display: block;
				background-color: #fff;
				margin: 1.5%;
			}
		}

		.Bclaname {
			color: #fd3f31 !important;
			border: 1px solid #fd3f31 !important;
		}

		#inmain {
			width: 60%;
			margin-left: 20%;
			height: 200px;
			margin-top: 3%;

			p {
				width: 100%;
				height: 30%;
				background-color: #EBEBEB;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 5%;
			}

			#text {
				width: 40%;
				display: inline-block;
				margin-left: 7%;

			}

			#rio {
				width: 50%;
				margin-left: 25%;
				margin-top: 30px;
			}
		}

		#FP {
			width: 60%;
			margin-left: 20%;
			margin-top: 3%;

			p {
				width: 100%;
				min-height: 65px;
				background-color: #EBEBEB;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 5%;
			}

			#text {
				width: 40%;
				display: inline-block;
				margin-left: 7%;

			}

			#rio {
				width: 50%;
				margin-left: 25%;
				margin-top: 30px;

				#Fpbox {
					margin-top: 30px;
				}
			}
		}

		#box {
			width: 60%;
			margin-left: 20%;
			height: 260px;

			#tit {
				width: 100%;
				height: 25%;
				background-color: #EBEBEB;
				display: flex;
				justify-content: space-between;
				align-items: center;

				p {
					margin: 0;
					width: 20%;
					text-align: center;
				}
			}

			#shop {
				width: 100%;
				height: 70%;
				display: flex;
				justify-content: space-between;
				text-align: center;
				align-items: center;

				div {
					width: 20%;

					img {
						width: 40%;
						max-height: 100%;
					}
				}

				p {
					margin: 0;
					width: 20%;
				}

				input {
					width: 20%;
				}
			}
		}

		#btn {
			width: 60%;
			margin-left: 20%;
			display: flex;
			justify-content: flex-end;
			align-content: center;
			margin-bottom: 30px;

			button {
				width: 140px;
				height: 40px;
				color: #FFFFFF;
				background-color: #fd3f31;
				border-radius: 5px;
			}
		}

		#codebox {
			width: 100%;
			text-align: center;

			.iconclas {
				font-size: 28px;
				color: #09BB07;
				margin-right: 5px;
			}

			span {
				font-size: 24px;
				color: #000000;
				display: inline-block;
				height: 40px;
				padding-bottom: 10px;
			}

			.qrcode {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 30px;
			}

			#txtbox {
				p {
					color: #999B9A;
					margin-bottom: 10px;
					height: 25px;

					span {
						font-size: 12px;
						color: #000000;
					}

					.pic {
						font-size: 20px;
						color: #fd3d31;
					}
				}
			}
		}
	}

	// 手机
	@media screen and (max-width:720px) {
		#Sinheader {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			box-shadow: 1px 1px 10px #dfd9d9;
			border-radius: 0px 0px 10px 10px;
			padding-left: 2%;
		}
		.Opclas {
			color: red;
		}
		.Bclaname {
			color: #fd3f31 !important;
			border: 1px solid #fd3f31 !important;
		}
		
		.verbox {
			height: 60%;
			display: flex;
			align-content: center;
			align-items: center;
			flex-wrap: wrap;
		
			button {
				width: 20%;
				height: 65px;
				color: #C0C4CC;
				border: 1px solid #C0C4CC;
				display: block;
				background-color: #fff;
				margin: 1.5%;
			}
		}

		#inmain {
			width: 100%;
			height: 200px;
			margin-top: 3%;

			p {
				width: 100%;
				height: 30%;
				background-color: #EBEBEB;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 5%;
			}

			#text {
				width: 40%;
				display: inline-block;
				margin-left: 7%;

			}

			#rio {
				width: 100%;
				margin-left: 5%;
				margin-top: 30px;
			}
		}

		#FP {
			width: 100%;
			margin-top: 3%;

			p {
				width: 100%;
				min-height: 65px;
				background-color: #EBEBEB;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-left: 5%;
			}

			#text {
				width: 40%;
				display: inline-block;
				margin-left: 7%;

			}

			#rio {
				width: 80%;
				margin-left: 10%;
				margin-top: 30px;

				#Fpbox {
					margin-top: 30px;
				}
			}
		}

		#box {
			width: 100%;
			height: 200px;

			#tit {
				width: 100%;
				height: 25%;
				background-color: #EBEBEB;
				display: flex;
				justify-content: space-between;
				align-items: center;

				p {
					margin: 0;
					width: 20%;
					text-align: center;
				}
			}

			#shop {
				width: 100%;
				height: 70%;
				display: flex;
				justify-content: space-between;
				text-align: center;
				align-items: center;

				div {
					width: 20%;

					img {
						// width: 40%;
						// max-height: 100%;
						display: none;
					}
				}

				p {
					margin: 0;
					width: 20%;
					font-size: 12px;
				}

				span {
					font-size: 14px;
				}

				input {
					width: 20%;
				}
			}
		}

		#btn {
			width: 60%;
			margin-left: 20%;
			display: flex;
			justify-content: center;
			align-content: center;
			margin-bottom: 30px;

			button {
				width: 140px;
				height: 40px;
				color: #FFFFFF;
				background-color: #fd3f31;
				border-radius: 5px;
			}
		}
	}
</style>
<style type="text/css">
	::-webkit-scrollbar {
		width: 0px;
		height: 10px;
	}
</style>
